import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ImageFullBig from '../../icons/fullscreen-big.svg';
import ImageFullCancel from '../../icons/fullscreen-cancel.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import {COMMAND_NAME__Full} from './constant';
import '../../css/style.css';

export default class FullScreenToolbarUI extends Plugin {
  init() {
    this._createToolbarButton();
  }

  _createToolbarButton() {
    const editor = this.editor;
    editor.ui.componentFactory.add(COMMAND_NAME__Full, (locale) => {
      const command = editor.commands.get(COMMAND_NAME__Full);
      const buttonView = new ButtonView(locale);
      buttonView.set({
        label: '全屏',
        icon: ImageFullBig,
        tooltip: true
      });
      buttonView.on('change:isOn', (eventInfo, name, value, oldValue) => {
        if (value == 1) {
          buttonView.set({
            label: '默认',
            icon: ImageFullCancel,
            tooltip: true
          });
        } else {
          buttonView.set({
            label: '全屏',
            icon: ImageFullBig,
            tooltip: true
          });
        }
      });
      // 将按钮的状态关联到命令对应值上
      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');
      // 点击按钮时触发相应命令
      this.listenTo(buttonView, 'execute', () => editor.execute(COMMAND_NAME__Full, buttonView));
      return buttonView;
    });
  }
}
