import Command from '@ckeditor/ckeditor5-core/src/command';

export default class FullScreenCommand extends Command {
  refresh() {
    this.isEnabled = true;
  }

  execute() {
    const myOptions = this.editor.config.get('fullScreen.options');
    let container = myOptions ? (myOptions.container) : null;
    if (!container) {
      return;
    }
    if (this.value == 1) {
      this.value = 0;
      if (container) {
        container.classList.remove('fullscreeneditor');
        document.body.classList.remove('fullscreenoverlay');
      }
      // if (this.editor.sourceElement.nextElementSibling === null) {
      // window.document.querySelector( '.document-editor' ).removeAttribute( 'id' );
      // } else {
      // this.editor.sourceElement.nextElementSibling.removeAttribute( 'id' );
      // }
      //
      // document.body.removeAttribute( 'id' );
    } else {
      this.value = 1;
      if (container) {
        container.classList.add('fullscreeneditor');
        document.body.classList.add('fullscreenoverlay');
      }
      // if (this.editor.sourceElement.nextElementSibling === null) {
      // document.querySelector( '.document-editor' ).setAttribute( 'id', 'fullscreeneditor' );
      // } else {
      // this.editor.sourceElement.nextElementSibling.setAttribute( 'id', 'fullscreeneditor' );
      // }
      //
      // document.body.setAttribute( 'id', 'fullscreenoverlay' );
    }
  }
}
