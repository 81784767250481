import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import FullScreenCommand from './command';
import { COMMAND_NAME__Full } from './constant';

export default class FullScreenEditing extends Plugin {
  static get pluginName() {
	return 'FullScreenEditing';
  }

  init() {
	console.log( 'init FullScreenEditing' );
	const editor = this.editor;
	// 注册一个 BoldCommand 命令
	editor.commands.add( COMMAND_NAME__Full, new FullScreenCommand( editor ) );
  }
}
